<template>
  <v-container>
      <template v-for="(item, i) in items">
        <v-col cols="6" :key=i v-if=item.path>
          <router-link :to=item.path v-slot="{ href, route, navigate, isActive }">
              <v-row :active="isActive" :href="href" v-on="routeExists(route, item) ? { click: navigate } : {}" >
                <v-btn elevation="2" small :disabled="routeAuthRequired(route, item)">
                  {{ route.name }} <span v-if=item.text>({{item.text}})</span>
                </v-btn>
              </v-row>
          </router-link>
        </v-col>
      </template>
  </v-container>
</template>

<script>
  import { firebase } from "@/middleware"
  export default {
    name: 'Main',

    computed: {
      user() {
        return firebase.auth().currentUser
      },
      displayName() {
        if (!this.user) return null
        const userEmail = (this.user && this.user.email) || null
        const displayName = (this.user && this.user.displayName) || null
        return displayName ? displayName : userEmail
      },
    },

    methods: {
      routeExists: function (route, item) {
        let is_routable = (route.name);

        if (item.auth_required) {
          is_routable = (this.user)
        }
        return is_routable
      },
      routeAuthRequired: function(route, item) {
        // console.log(route, 'route')
        // console.log(this.user, 'user')
        
        return (!route.name || (item.auth_required && !this.user))
      },
    },

    data: () => ({
      items: [
        { icon: 'mdi-contacts', text: '', path: '/' },
        { icon: 'mdi-history', text: '', path: '/public' },
        { icon: 'mdi-history', text: 'auth', path: '/dashboard', auth_required: true },
        { icon: 'mdi-history', text: 'auth', path: '/protected', auth_required: true },
        {
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'Labels',
          model: true,
          children: [
            { icon: 'mdi-plus', text: 'Create label' },
          ],
        },
        {
          icon: 'mdi-chevron-up',
          'icon-alt': 'mdi-chevron-down',
          text: 'More',
          model: false,
          children: [
            { text: 'Import' },
            { text: 'Export' },
            { text: 'Print' },
            { text: 'Undo changes' },
            { text: 'Other contacts' },
          ],
        },
        { icon: 'mdi-settings', text: 'Settings' },
        { icon: 'mdi-message', text: 'Send feedback' },
        { icon: 'mdi-help-circle', text: 'Help' },
        { icon: 'mdi-cellphone-link', text: 'App downloads' },
        { icon: 'mdi-keyboard', text: 'Go to the old version' },
      ],
      ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
      importantLinks: [
        {
          text: 'Documentation',
          href: 'https://vuetifyjs.com',
        },
        {
          text: 'Chat',
          href: 'https://community.vuetifyjs.com',
        },
        {
          text: 'Made with Vuetify',
          href: 'https://madewithvuejs.com/vuetify',
        },
        {
          text: 'Twitter',
          href: 'https://twitter.com/vuetifyjs',
        },
        {
          text: 'Articles',
          href: 'https://medium.com/vuetify',
        },
      ],
      whatsNext: [
        {
          text: 'Explore components',
          href: 'https://vuetifyjs.com/components/api-explorer',
        },
        {
          text: 'Select a layout',
          href: 'https://vuetifyjs.com/layout/pre-defined',
        },
        {
          text: 'Frequently Asked Questions',
          href: 'https://vuetifyjs.com/getting-started/frequently-asked-questions',
        },
      ],
    }),
  }
</script>
