// import HelloWorld from '@/components/HelloWorld.vue'
import NotFoundComponent from '@/components/NotFound.vue'

import { guard as AuthGuard } from "@/middleware"

export default [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '../components/HelloWorld.vue'),
    // beforeEnter: AuthGuard,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    beforeEnter: AuthGuard,
  },
  {
    path: '/public',
    name: 'public',
    component: () => import(/* webpackChunkName: "about" */ '../views/Public.vue'),
    // beforeEnter: AuthGuard,
  },
  {
    path: '/protected',
    name: 'protected',
    beforeEnter: AuthGuard,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Protected.vue')
  },
  {
    path: '*', 
    component: NotFoundComponent 
  }
]
