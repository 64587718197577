<template>
  <div class="home">

  </div>
</template>

<script>
// @ is an alias to /src
// import Main from '@/components/Main.vue'

export default {
  name: 'NotFound',
  components: {}
}
</script>
