import Vue from 'vue'
import App from "@/App"
import './registerServiceWorker'
import router from "@/router"
import store from './store'
import vuetify from "@/plugins/vuetify"
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import { firebase } from "@/middleware"
import AuthGuard from "./wrapper"

Vue.config.productionTip = false

const authGuardSettings = {
  router: router, // routes
  firebase: firebase, // pass on firebase middleware app init
  verification: false, // require user email to be verified before granting access
  registration: false, // allow new user registrations
  phone: false, // allow authentication with phone
  google: true, // allow authentication with gmail account
  facebook: false, // allow authentication with facebook account
  title: "Authenticate",
  subtitle: "",
  icon: "mdi-brightness-7", // authentication prompt icon
  iconColor: "orange", // authentication prompt icon color
}

Vue.use(AuthGuard, authGuardSettings)

let app;

firebase.auth().onAuthStateChanged(() => {
  if(!app){
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }
})