<template>
<v-app id="app">
  <v-navigation-drawer
    v-model="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-list dense>
      <Main/>
      <template v-for="item in items">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center"
        >
          <v-col cols="6">
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
          <v-col
            cols="6"
            class="text-center"
          >
            <a
              href="#!"
              class="body-2 black--text"
            >EDIT</a>
          </v-col>
        </v-row>
        <v-list-group
          v-else-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            link
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="item.text"
          link
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="blue darken-3"
    dark
  >
    <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    <v-toolbar-title
      style="width: 300px"
      class="ml-0 pl-4"
    >
      <span @click="goHome()" class="title ml-3 mr-5">Web<span class="font-weight-light">Flows</span></span>
    </v-toolbar-title>
    <!-- <v-text-field
      flat
      solo-inverted
      hide-details
      prepend-inner-icon="mdi-magnify"
      label="Search"
      class="hidden-sm-and-down"
    /> -->
    <v-toolbar-title v-if=displayName>
        Welcome!: <v-chip>{{ displayName }}</v-chip>
    </v-toolbar-title>

    <v-spacer />

    <!-- sign in / sign out button -->

    <v-btn v-if="!user" outlined @click="$router.push('/protected').catch(() => {})"> Sign In </v-btn>
    <v-btn v-else outlined @click="signOut()"> Sign Out </v-btn>
    <v-btn icon>
      <v-icon>mdi-apps</v-icon>
    </v-btn>
    <v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>
    <!--
    <v-btn
      icon
      large
    >
      <v-avatar
        size="32px"
        item
      >
        <v-img
          src="https://cdn.vuetifyjs.com/images/logos/logo.svg"
          alt="Vuetify"
        /></v-avatar>
    </v-btn>
    -->
  </v-app-bar>
  <v-main>
    <!-- <v-container v-if="!user"> -->
    <v-container>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
      <hr />
    </v-container>
  </v-main>
  <!-- auth guard -->
  <AuthenticationGuard />
  <!-- <v-btn
    bottom
    color="orange"
    dark
    fab
    fixed
    right
    @click="dialog = !dialog"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn> -->
  <v-dialog
    v-model="dialog"
    width="800px"
  >
    <v-card>
      <v-card-title class="grey darken-2">
        Create contact
      </v-card-title>
      <v-container>
        <v-row class="mx-2">
          <v-col
            class="align-center justify-space-between"
            cols="12"
          >
            <v-row
              align="center"
              class="mr-0"
            >
              <v-avatar
                size="40px"
                class="mx-3"
              >
                <img
                  src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                  alt=""
                >
              </v-avatar>
              <v-text-field
                placeholder="Name"
              />
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-text-field
              prepend-icon="mdi-account-card-details-outline"
              placeholder="Company"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              placeholder="Job title"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              prepend-icon="mdi-mail"
              placeholder="Email"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="tel"
              prepend-icon="mdi-phone"
              placeholder="(000) 000 - 0000"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              prepend-icon="mdi-text"
              placeholder="Notes"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn
          text
          color="primary"
        >More</v-btn>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="dialog = false"
        >Cancel</v-btn>
        <v-btn
          text
          @click="dialog = false"
        >Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
  import Main from './components/Main';
  import { firebase } from "@/middleware"
  // const Main = {}

  export default {
    name: 'App',
    components: {Main},

    computed: {
      user() {
        return firebase.auth().currentUser
      },
      displayName() {
        if (!this.user) return null
        const userEmail = (this.user && this.user.email) || null
        const displayName = (this.user && this.user.displayName) || null
        return displayName ? displayName : userEmail
      },
    },
    methods: {
      goHome(path = '/') {
        this.$router.push(path).then(() => {
            console.log("GO HOME")
            this.forceRerender()
          }).catch(() => {})
      },
      signOut() {
        firebase
          .auth()
          .signOut()
          .then(() => {
            this.goHome('/logout')
          })
          .catch((error) => console.error("Failed to sign out the user!", error))
        return
      },
      forceRerender() {
        location.reload()
      },
    },
    props: {
      source: String,
    },
    data: () => ({
      dialog: false,
      drawer: false,
      componentAppKey: 0,
      items: [{
        heading: "text"
      }],
    }),
  }
</script>
